import jwt_decode from "jwt-decode";

/**
 * Authentication wallet
 */

let authTokenObj;

export async function getAuthToken() {
  // if all is good then, give back
  if (authTokenObj) {
    if (authTokenObj.decoded.exp > new Date().getTime() / 1000) {
      return authTokenObj.encoded;
    }
  }

  const refreshTokenEncoded = window.localStorage.getItem("refresh-token");
  if (!refreshTokenEncoded) {
    return "";
    // don't throw error
  }

  const refreshToken = jwt_decode(refreshTokenEncoded);
  if (refreshToken.exp < new Date().getTime() / 1000) {
    // time to refresh
    // might want to just force disconnect
    window.localStorage.removeItem("refresh-token");
    alert("need to re-login");
    return "";
  }

  let res = await fetch(
    `https://2dbq8dxo12.execute-api.us-east-1.amazonaws.com/prod/auth/v1.1/token/bearer`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: refreshTokenEncoded,
      },
    }
  );
  let resBody = await res.json();

  if (!resBody.authToken) {
    alert("AUTH TOKEN ERROR:" + resBody.message);
    window.localStorage.removeItem("refresh-token");
    return "";
  }

  authTokenObj = {
    encoded: resBody.authToken,
    decoded: jwt_decode(resBody.authToken),
  };

  return authTokenObj.encoded;
}

export async function setupRefreshToken(address, provider) {
  // stop wasted run
  const refreshTokenEncoded = window.localStorage.getItem("refresh-token");
  if (refreshTokenEncoded) {
    const refreshToken = jwt_decode(refreshTokenEncoded);
    if (refreshToken.exp > new Date().getTime() / 1000) {
      return;
    }
  }

  const web3 = new Web3(provider);

  let res = await fetch(
    `https://2dbq8dxo12.execute-api.us-east-1.amazonaws.com/prod/auth/nonce?walletAddress=${address}`
  );
  let resBody = await res.json();
  const decodedToken = jwt_decode(resBody.nonceToken);
  let signature = await web3.eth.personal.sign(
    decodedToken.nonce + ":" + address,
    address
  );

  let opts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      nonceToken: resBody.nonceToken,
      signature: signature,
    }),
  };

  res = await fetch(
    `https://2dbq8dxo12.execute-api.us-east-1.amazonaws.com/prod/auth/v1.1/token/refresh`,
    opts
  );
  resBody = await res.json();

  if (!resBody.refreshToken) alert(resBody.message);

  window.localStorage.setItem("refresh-token", resBody.refreshToken);

  return resBody;
}
