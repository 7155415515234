import { authenticate, getAuthToken } from "./authenticate";
import jwt_decode from "jwt-decode";

export function findObjectByKey(array, key, value) {
  try {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  } catch (err) {
    console.log(`ERROR findObjectByKey`, err.message);
  }
}

var provider;
var account;
var userData;
var userBalance;
var userNFTIds;

export function LoginProtect() {
  const ret = ValidateLogin();
  if (ret) return;
  window.location.href = "/?mode=login";
}

export function ValidateLogin() {
  if (userData && userData.authToken) {
    const decodedToken = jwt_decode(userData.authToken);
    if (decodedToken.exp > new Date().getTime() / 1000) {
      return true;
    } else {
      ClearUserData();
    }
  }
  return false;
}

export function TimeoutPromise(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

let GetUserData_status = "ready";

export async function GetUserData() {

  // serialize this operation
  while (GetUserData_status === "block") {
    await TimeoutPromise(500);
  }

  GetUserData_status = "block";
  const ret = await getAuthToken();
  GetUserData_status = "ready";

  return ret;
}

export function SyncDownUserData() {
  const tmp = window.localStorage.getItem("userData");
  if (tmp && tmp !== undefined) userData = JSON.parse(tmp);
  return userData;
}

export function SetUserData(_userData) {
  if (_userData && _userData.authToken) {
    const tmp = JSON.stringify(_userData);
    window.localStorage.setItem("userData", tmp);
    userData = _userData;
  }
}

export function ClearUserData() {
  userData = undefined;
  window.localStorage.clear("userData");
}

export function GetProvider() {
  return provider;
}
export function SetProvider(_prov) {
  provider = _prov;
}

export function GetSelectedAccount() {
  return account;
}
export function SetSelectedAccount(_acc) {
  account = _acc;
}

export function SetUserCounts(_bal) {
  userBalance = _bal;
}

export function GetUserCounts() {
  return userBalance;
}

export function SetNFTIds(_ids) {
  userNFTIds = _ids;
}

export function GetNFTIds() {
  return userNFTIds;
}

export function checkAvailability() {
  // removing older refrsh-token check
  if (
    userBalance < 3 ||
    !account ||
    account === undefined
  ) {
    return false;
  }
  return true;
}
