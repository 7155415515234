import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import $ from "jquery";
import {
  findObjectByKey,
  GetUserData,
  GetSelectedAccount,
  SetProvider,
  SetSelectedAccount,
  SetUserData,
  GetProvider,
  SetUserCounts,
  GetUserCounts,
  ClearUserData,
  SetNFTIds,
  GetNFTIds,
  checkAvailability,
} from "./helpers";
import { setupRefreshToken } from "./authenticate";
import {
  cantiCount,
  numberCollection,
  portfolioAnalysis,
  portfolioCircle,
  portfolioCount,
  portfolioCount1,
  portfolioCount2,
  portfolioValue,
  totalNftsOwn,
} from "./chartIds";

$(document).click(function (event) {
  if ($("#filterList").hasClass("hideFilter") === false) {
    $("#filterList").addClass("hideFilter");
  }
});

$(drawPages());

async function drawProfileTop(getName, getObject) {
  const resp = await $.ajax({
    url: `https://2dbq8dxo12.execute-api.us-east-1.amazonaws.com/public/plot?dataSet=collections&skip=0&search=%7B%22name%22:%7B%22$regex%22:%22${getName}%22,%22$options%22:%22i%22%7D%7D&limit=1`,
    method: "GET",
    timeout: 0,
    headers: {
      "Content-Type": "application/json",
    },
  });
  await profilePageDataApply({
    ...resp.data[0],
    supply: getObject.totalSupply,
  });
}

async function drawPages() {
  const location = window.location.pathname;
  await renderList();
  if (location === "/" || location === "/trend" || location === "/trend.html") {
    if (location === "/trend") {
      $("#trend-menu").addClass(" active");
    }
    $(drawLandingPage());
  } else if (location === "/portfolio" || location === "/portfolio.html") {
    $("#portfolio-menu").addClass(" active");
    $(drawPortfolio());
  } else if (
    location.includes("/profile") ||
    location.includes("/profile.html")
  ) {
    $("#profile-menu").addClass(" active");
    const getName = getParam(`name`);
    if (typeof getName !== "undefined" && getName) {
      var response = await $.ajax({
        url: `https://2dbq8dxo12.execute-api.us-east-1.amazonaws.com/public/plot?dataSet=trends&skip=0&search=%7B%22name%22:%7B%22$regex%22:%22${getName}%22,%22$options%22:%22i%22%7D%7D&limit=1`,
        method: "GET",
        timeout: 0,
        headers: {
          "Content-Type": "application/json",
        },
      });
      const getObject = response.data[0];

      if (typeof getObject !== "undefined" && getObject) {
        drawProfileTop(getName, getObject);
        setInterval(function () {
          drawProfileTop(getName, getObject);
        }, 30000);
        profileChart(getObject);
        const ava = checkAvailability();
        // if (ava === true) {
        await bottomProfileCharts(getObject.name);
        // }
        // console.log ( 'getObject', getObject );
      }
    }
  }
  const _available = GetUserCounts() >= 3 && GetSelectedAccount();
  if (_available) {
    const hide = document.querySelector("#cloudkey");
    if (hide) {
      hide.style = "display: none";
    }
  }
  if (location !== "/portfolio" && location !== "/portfolio.html") {
    const hide = document.querySelector("#cloudkey");
    if (hide) {
      hide.style = "display: none";
    }
  }
}

async function drawLandingPageTop() {
  const salesData = await $.ajax({
    url: `https://2dbq8dxo12.execute-api.us-east-1.amazonaws.com/public/plot?dataSet=marketData`,
    method: "GET",
    timeout: 0,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // landing page - volume 24h
  var selector = document.querySelector("#landing-vol24h");
  var tmp = parseFloat(salesData.data.volumeTotal1Day.toFixed(2));
  selector.innerHTML = tmp.toString();

  // landing page - total volume
  selector = document.querySelector("#landing-totalvol");
  tmp = parseFloat(salesData.data.volumeTotalGrand.toFixed(2));
  selector.innerHTML = tmp.toString();
  // landing page - sales 24h
  selector = document.querySelector("#landing-sale24h");
  selector.innerHTML = salesData.data.salesTotal1Day;
}

var selectedFilter = null;

async function drawLandingPage() {
  drawLandingPageTop();
  setInterval(function () {
    drawLandingPageTop();
  }, 30000);

  const isHomePageChart_available = $(`#home-page-chart`).length;
  if (isHomePageChart_available > 0) {
    renderChart();
    setInterval(function () {
      if (selectedFilter === null) {
        renderChart();
      } else {
        renderChart(
          selectedFilter.fieldName,
          selectedFilter.field,
          selectedFilter.dir
        );
      }
    }, 30000);
  }
  const homePageRightChart_available =
    $(`#home-page-right-chart1`).length && $(`#home-page-right-chart2`).length;
  if (homePageRightChart_available > 0) {
    render_right_chart_homepage();
  }
}

async function drawPortfolio() {
  const address = GetSelectedAccount();
  $("#wallet-address").text(address);
  const ava = checkAvailability();
  if (ava === false) return;
  $("a[href='https://alto.build/profile/']").attr(
    "href",
    `https://alto.build/profile/${address}`
  );
  analysisChart();
  circleChart();
  collectionCountsChart();
  drawPortfolioTop4Data();
  drawNFTs();
}

async function drawNFTs() {}

var sortableFields = {
  "24h vol": {
    field: "volume.total1Day.valueShort",
    sortDir: -1,
  },
  "floor price": {
    field: "floorPrice.valueShort",
    sortDir: -1,
  },
  mcap: {
    field: "marketCap.valueShort",
    sortDir: -1,
  },
  "7d vol": {
    field: "volume.total7Day.valueShort",
    sortDir: -1,
  },
  "total vol": {
    field: "volume.totalGrand.valueShort",
    sortDir: -1,
  },
  holders: {
    field: "holderCount",
    sortDir: -1,
  },
};

async function renderChart(_sortField, _sortFilter, _sortDir) {
  var sortFilter = _sortFilter;
  var sortDir = _sortDir;
  var sortField = _sortField;
  if (!sortField) {
    sortField = "24h vol";
  }
  if (!sortFilter) {
    sortFilter = "volume.total1Day.valueShort";
  }
  if (!sortDir) {
    sortDir = "-1";
  }
  var t =
    window.location.pathname === "/trend" ||
    window.location.pathname === "/trend.html"
      ? 50
      : 10;
  const chartIdChunksResponse = await $.ajax({
    url: `https://2dbq8dxo12.execute-api.us-east-1.amazonaws.com/public/plot?dataSet=trends&limit=${t}&sort={"${sortFilter}":${sortDir}}`,
    method: "GET",
    timeout: 0,
    headers: {
      "Content-Type": "application/json",
    },
  });
  const chartData = chartIdChunksResponse.data;
  var chartHeader = [
    "collection",
    "24h vol",
    "floor price",
    "mcap",
    "7d vol",
    "total vol",
    "holders",
  ];
  var filteredChart = [];
  for (var i = 0; i < chartData.length; i++) {
    filteredChart.push({
      collection: chartData[i].name,
      "24h vol": parseFloat(chartData[i].volume.total1Day.valueShort)
        .toFixed(2)
        .toString(),
      "floor price": parseFloat(chartData[i].floorPrice.valueShort)
        .toFixed(2)
        .toString(),
      mcap: parseFloat(chartData[i].marketCap.valueShort).toFixed(2).toString(),
      "7d vol": parseFloat(chartData[i].volume.total7Day.valueShort)
        .toFixed(2)
        .toString(),
      "total vol": parseFloat(chartData[i].volume.totalGrand.valueShort)
        .toFixed(2)
        .toString(),
      holders: chartData[i].holderCount,
      slug: chartData[i].slug,
      avatar: chartData[i].thumbnail,
    });
  }
  drawChart(chartHeader, filteredChart, sortField, "home-page-chart");
}

function drawChart(header, data, field, id) {
  var styles = "250px ";
  var tempColumns = header
    .map((_, _index) => {
      if (_index > 0) {
        return "1fr";
      } else {
        return "";
      }
    })
    .join(" ");
  var downImage =
    '<img src="../dist/down.png" alt="up" style="filter: brightness(0) invert(1);" />';
  var upImage =
    '<img src="../dist/up.png" alt="down" style="filter: brightness(0) invert(1);" />';
  styles = styles + tempColumns;
  var tableData =
    `<div class="chart-table__head">
    <div class="chart-table__row" style="` +
    `grid-template-columns:${styles}; display: grid` +
    `">`;
  var tableHeader = header
    .map((item, _index) => {
      if (_index === 0) {
        return `<div class="chart-table__cell">
          ${item}
        </div>`;
      } else if (_index === header.length - 1) {
        return `<div class="chart-table__cell" style="gap: 4px;cursor: pointer;" id="trend-${item
          .toLowerCase()
          .replaceAll(/\s/g, "")}" style="cursor: pointer;">
          ${
            item === field
              ? sortableFields[item].sortDir === -1
                ? downImage
                : upImage
              : ""
          }
          ${item}
        </div>`;
      } else {
        return `<div class="chart-table__cell" style="gap: 4px;cursor: pointer;" id="trend-${item
          .toLowerCase()
          .replaceAll(/\s/g, "")}">
          ${
            item === field
              ? sortableFields[item].sortDir === -1
                ? downImage
                : upImage
              : ""
          }
          ${item}
          <img src="../dist/csymbol.png" alt="c" style="filter: brightness(0) invert(1);" />
        </div>`;
      }
    })
    .join("");
  var height = data.length * 55;
  if (height >= 850) {
    height = 850;
  }
  tableData = tableData + tableHeader;
  tableData =
    tableData +
    `</div> </div> <div class="chart-table__body" style="height: ${height}px">`;
  var tableBody = data
    .map((item, _index) => {
      var row =
        `<div class='chart-table__row' style="` +
        `grid-template-columns:${styles}; display: grid; cursor: pointer;` +
        `" onclick="window.location.href=` +
        `'${window.location.origin}/profile.html?name=${item.collection}'` +
        `">`;
      var cells = header
        .map((_head, _index) => {
          if (_index === 0) {
            var collogo = "";
            if (item.avatar) {
              collogo =
                collogo +
                `<img src="` +
                item.avatar +
                `" width="100%" height="100%" alt="avatar"/>`;
            }
            return (
              `<div class="chart-table__cell">
              <div class="collection-avatar">` +
              collogo +
              `</div>
              ${item[_head]}
            </div>`
            );
          } else {
            return `<div class="chart-table__cell">${item[_head]}</div>`;
          }
        })
        .join("");
      row = row + cells;
      row = row + "</div>";
      return row;
    })
    .join("");
  tableData = tableData + tableBody;
  tableData = tableData + `</div>`;

  const selectedTable = document.querySelector(`#${id}`);
  selectedTable.innerHTML = tableData;

  document
    .getElementById("trend-24hvol")
    .addEventListener("click", function () {
      clickedSort("24h vol");
    });
  document
    .getElementById("trend-floorprice")
    .addEventListener("click", function () {
      clickedSort("floor price");
    });
  document.getElementById("trend-mcap").addEventListener("click", function () {
    clickedSort("mcap");
  });
  document.getElementById("trend-7dvol").addEventListener("click", function () {
    clickedSort("7d vol");
  });
  document
    .getElementById("trend-totalvol")
    .addEventListener("click", function () {
      clickedSort("total vol");
    });
  document
    .getElementById("trend-holders")
    .addEventListener("click", function () {
      clickedSort("holders");
    });
}

async function clickedSort(_sortField) {
  selectedFilter = {
    fieldName: _sortField,
    field: sortableFields[_sortField].field,
    dir: sortableFields[_sortField].sortDir,
  };
  renderChart(
    _sortField,
    sortableFields[_sortField].field,
    sortableFields[_sortField].sortDir
  );
  sortableFields[_sortField].sortDir = -sortableFields[_sortField].sortDir;
}

async function render_right_chart_homepage() {
  const ava = checkAvailability();
  // if (ava === false) return;
  const chartSdk = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-project-0-bgadz",
    showAttribution: false,
    autoRefresh: true,
    maxDataAge: 60,
    widthMode: "scale",
    // getUserToken: GetUserData,
  });

  const chart1 = chartSdk.createChart({
    chartId: "4aec2753-6775-4d7b-8ab0-fe6292cabd08",
  });
  const chart2 = chartSdk.createChart({
    chartId: "5b43d095-f3ff-4496-b734-5eaf12251ee0",
  });
  // await chart.setFilter({ "project.symbol": symbol });
  chart1.render(document.getElementById("home-page-right-chart1"));
  chart2.render(document.getElementById("home-page-right-chart2"));

  $("#refresh").on("click", () => {
    chart1.refresh();
    chart2.refresh();
  });
}

// testing

async function profilePageDataApply(obj) {
  try {
    if (typeof obj.thumbnail !== "undefined" && obj.thumbnail) {
      $(`.profile__img img`).attr(`src`, obj.thumbnail);
    }

    if (typeof obj.name !== "undefined" && obj.name) {
      $(`.profile__content--title`).text(obj.name);
    }

    if (typeof obj.symbol !== "undefined" && obj.symbol) {
      $(`.list-unstyled__symbol`).text(obj.symbol);
    } else {
      $(`.list-unstyled__symbol`).remove();
    }

    if (typeof obj.contract !== "undefined" && obj.contract) {
      $(`.list-unstyled__oxasdeasd`).text(obj.contract);
    } else {
      $(`.list-unstyled__oxasdeasd`).remove();
    }

    if (typeof obj.created !== "undefined" && obj.created) {
      const createAt = new Date(obj.created);
      $(`.list-unstyled__createdAt`).text(
        `${createAt.getFullYear()}-${createAt.getMonth()}-${createAt.getDay()}`
      );
    } else {
      $(`.list-unstyled__createdAt`).remove();
    }

    if (typeof obj.royalty !== "undefined" && obj.royalty) {
      $(`.profile__list .profile__list__royalty`).text(
        `${(obj.royalty * 100).toFixed(2)}% Royalties`
      );
    } else {
      $(`.profile__list .profile__list__royalty`).remove();
    }

    if (typeof obj.supply !== "undefined" && obj.supply) {
      $(`.profile__list .profile__list__supply`).text(`${obj.supply} Supply`);
    } else {
      $(`.profile__list .profile__list__supply`).remove();
    }

    if (typeof obj.slug !== "undefined" && obj.slug) {
      $(`.profile__social .badge_altoProfile a`).attr({
        href: `https://alto.build/collections/${obj.slug}`,
        target: "_blank",
      });
    } else {
      $(`.profile__social .badge_altoProfile`).remove();
    }

    if (typeof obj.twitter !== "undefined" && obj.twitter) {
      $(`.profile__social .badge_twitter a`).attr({
        href: obj.twitter,
        target: "_blank",
      });
    } else {
      $(`.profile__social .badge_twitter`).remove();
    }

    if (typeof obj.website !== "undefined" && obj.website) {
      $(`.profile__social .badge_web a`).attr({
        href: obj.twitter,
        target: "_blank",
      });
    } else {
      $(`.profile__social .badge_web`).remove();
    }

    if (typeof obj.discord !== "undefined" && obj.discord) {
      $(`.profile__social .badge_discord a`).attr({
        href: obj.discord,
        target: "_blank",
      });
    } else {
      $(`.profile__social .badge_discord`).remove();
    }

    $(`.profile`).removeClass(`d-none`);
  } catch (err) {
    console.log(`ERROR `, err.message);
  }
}

function profileChart(obj) {
  var selector = document.querySelector("#profile-floor");
  var tmp = parseFloat(obj.floorPrice.valueShort.toFixed(2));
  // profile - floor price
  selector.innerHTML = tmp.toString();
  // profile - total volume
  selector = document.querySelector("#profile-totalvol");
  tmp = parseFloat(obj.volume.totalGrand.valueShort.toFixed(2));
  selector.innerHTML = tmp.toString();
  // profile - marketcap
  selector = document.querySelector("#profile-mcap");
  tmp = parseFloat(obj.marketCap.valueShort.toFixed(2));
  selector.innerHTML = tmp.toString();
  // profile - holders
  selector = document.querySelector("#profile-holders");
  selector.innerHTML = obj.holderCount;
}

async function renderList(projects) {
  // window.alert('SSS')
  $("#project-selector-div").show();
  if (projects === undefined || !projects) return;
  var collections = projects.data;
  window.filterCollections = [];
  // console.log('@@@@@', collections)
  // window.alert('agaya')
  const parent = $("#project-selector-ol");
  $(`#filterList`).html("");
  collections.forEach((collection) => {
    // console.log('chaa', collection);
    window.filterCollections.push(collection);
    $(`#filterList`).append(`
        <li>
          <a href="${window.location.origin}/profile.html?name=${collection.name}" class="d-flex justify-content-between align-items-center">
            <span>${collection.name}</span>
            <span>
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 1C7.5 0.723858 7.27614 0.5 7 0.5H2.5C2.22386 0.5 2 0.723858 2 1C2 1.27614 2.22386 1.5 2.5 1.5H6.5V5.5C6.5 5.77614 6.72386 6 7 6C7.27614 6 7.5 5.77614 7.5 5.5V1ZM1.35355 7.35355L7.35355 1.35355L6.64645 0.646447L0.646447 6.64645L1.35355 7.35355Z" fill="#475165"/>
              </svg>
            </span>
          </a>
        </li>
      `);
    // const childLi = document.createElement('li');
    // parent.append(childLi);
    // if (collection.thumbnail) {
    //     if (collection.thumbnail.startsWith("/assets")) collection.thumbnail = "https://alto.build" + collection.thumbnail;
    //     const childLogo = document.createElement('img');
    //     childLi.appendChild(childLogo);
    //     childLogo.setAttribute("src", collection.thumbnail);
    //     childLogo.style = "width: 30px;"
    // }
    // const childAnchor = document.createElement('a');
    // childLi.appendChild(childAnchor);
    // // childAnchor.setAttribute('href',`/?mode=analytics&symbol=${collection.symbol}`);
    // childAnchor.innerHTML = `${collection.name}`;
  });
}

$(document).on(`keyup`, `#filterInput`, async function (e) {
  try {
    e.stopImmediatePropagation();
    const val = $(this).val().toLowerCase();
    let matchingCount = 0;

    var projects = await $.ajax({
      url: `https://2dbq8dxo12.execute-api.us-east-1.amazonaws.com/public/plot?dataSet=collections&skip=0&search=%7B%22name%22:%7B%22$regex%22:%22${val}%22,%22$options%22:%22i%22%7D%7D&limit=10`,
      method: "GET",
      timeout: 0,
      headers: {
        "Content-Type": "application/json",
      },
    });

    renderList(projects);

    if (val.length > 0) {
      $(`#filterList li`).each(function () {
        const text = $(this).find(`a`).text().trim().toLowerCase();
        if (text.includes(val)) {
          $(this).show();
          matchingCount++;
        } else {
          $(this).hide();
        }
      });
      $(`#filterList`).removeClass(`hideFilter`);
    } else {
      $(`#filterList`).addClass(`hideFilter`);
    }
  } catch (err) {
    console.log(`ERROR #filterInput`, err.message);
  }
});

function getParam(paramIs) {
  try {
    const url = location.href;
    const objURL = new URL(url);
    const c = objURL.searchParams.get(paramIs);
    return c;
  } catch (err) {
    console.log("ERROR getParam", err.message);
  }
}

const row1_4 = [
  // row 1
  {
    chartId: "f9ad2eaf-f593-4b41-83a5-940b05abf0c4",
    id: "vol24h",
    filter: true,
  },
  {
    chartId: "7f373611-05a3-41d0-b532-455af6192746",
    id: "sale-count",
    filter: true,
  },
  {
    chartId: "67832bde-c7fe-4c07-bc45-2ec752a09d26",
    id: "percent-sold",
    filter: true,
  },
  {
    chartId: "d9611c5b-e691-4416-b7d7-703f3a9b768b",
    id: "highest-sale",
    filter: true,
  },
  {
    chartId: "3ff3ebd8-73c4-40b7-b50f-9b2c8646d819", // sales plot
    id: "sell-price-chart",
    filter: true,
  },
  {
    chartId: "63ea54fc-67a6-41fa-827f-0caf75acaf97", // floor price
    id: "sales-chart",
    filter: true,
  },
  {
    chartId: "6400b7ec-0582-4550-8311-a539d42f10ab", // sales list
    id: "sales-data-chart",
    filter: true,
  },
  {
    chartId: "63ea20e3-16d4-4087-8f8b-22851c2b7a7e", // floor depth
    id: "counts-chart1",
    filter: true,
  },
  // {
  //   chartId: "c4003b8e-7958-4e41-9d5e-59d2b88aa73e",
  //   id: "counts-chart2",
  //   filter: true,
  // },
  {
    chartId: "acd10e43-4a57-45ca-a8da-20a327e2502b", // mcap1
    id: "market-trend-chart",
    filter: true,
  },
  {
    chartId: "1cfdbd20-ef04-49e7-bda7-d9c457c1cc7d", // mcap2
    id: "buyers-trend-chart",
    filter: true,
  },
  {
    chartId: "6405e433-e7f9-4ed1-8d84-04cbc01a10fd", // recently listed: right side
    id: "listing-chart",
    filter: true,
  },
  {
    chartId: "e4cfafcc-29e6-4daa-856f-ce5e820bc94f ", // list count daily
    id: "extra-listing-count",
    filter: true,
  },
  {
    chartId: "48decb9a-1b3e-401d-b156-1e8419dfbe18", // extra list count daily
    id: "extra-listing-count-daily",
    filter: true,
  },
  {
    chartId: "b04f0666-c183-428a-bf7a-f4af829dac44", // top circle
    id: "holder-row-1",
    filter: true,
  },
  {
    chartId: "49cc410d-8f24-4ed4-a784-e6d0dddb04e4", // top holders
    id: "holder-row-2",
    filter: true,
  },
  {
    chartId: "63f5de26-f15d-4f78-8a1f-1a0cb4663f45", // holder info
    id: "holder-row-3",
    filter: false,
  },
];
async function bottomProfileCharts(name) {
  const ava = checkAvailability();
  // if (ava === false) return;
  var chartIdChunks = row1_4;

  const chartSdk = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-project-0-bgadz",
    showAttribution: false,
    autoRefresh: true,
    maxDataAge: 60,
    widthMode: "scale",
    // getUserToken: GetUserData,
  });

  for (let index = 0; index < chartIdChunks.length; index++) {
    const chartIdObj = chartIdChunks[index];

    const chart = chartSdk.createChart(
      chartIdObj.filter === true
        ? {
            chartId: chartIdObj.chartId,
            filter: {
              "project.name": name,
            },
          }
        : {
            chartId: chartIdObj.chartId,
          }
    );
    const elem = document.getElementById(chartIdObj.id);
    if (elem) {
      chart.render(elem);
    } else {
      console.warn("Missing chart container: " + chartIdObj.id);
    }
    $("#refresh").on("click", () => {
      chart.refresh();
    });
  }
}

/******************************/
/* Updated at: 2023-02-20     */
/* Author: luckypanda         */
/* Feature: web3modal connect */
/******************************/

// Unpkg imports
const Web3Modal = window.Web3Modal.default;
const WalletConnectProvider = window.WalletConnectProvider.default;
const evmChains = window.evmChains;

// Web3modal instance
let web3Modal;

// Chosen wallet provider given by the dialog window
let provider;

// Address of the selected account
let selectedAccount;

// Status of FetchData
let fetchLoading = false;

// Cantos Network Data
const NETWORKDATA = {
  7700: {
    chainId: "0x1e14",
    rpcUrls: ["https://canto.gravitychain.io/"],
    chainName: "CANTO",
    nativeCurrency: { name: "CANTO", decimals: 18, symbol: "CANTO" },
    blockExplorerUrls: ["https://tuber.build/"],
  },
};

// Utils - Reduced Address
function reducedAddress(address) {
  if (!address) return null;
  return address.slice(0, 6) + "..." + address.slice(-4);
}

/**
 * Setup the orchestra
 */
function init() {
  console.log("Initializing example");
  console.log("WalletConnectProvider is", WalletConnectProvider);

  // Check that the web page is run in a secure context,
  // as otherwise MetaMask won't be available
  // if (location.protocol !== "https:") {
  //   // https://ethereum.stackexchange.com/a/62217/620
  //   const alert = document.querySelector("#alert-error-https");
  //   alert.style.display = "block";
  //   document.querySelector("#btn-connect").setAttribute("disabled", "disabled");
  //   return;
  // }

  // Tell Web3modal what providers we have available.
  // Built-in web browser provider (only one can exist as a time)
  // like MetaMask, Brave or Opera is added automatically by Web3modal
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "8043bb2cf99347b1bfadfb233c5325c0",
        rpc: { 7700: "https://canto.gravitychain.io/" },
      },
    },
  };

  web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions, // required
    theme: "dark",
  });

  console.log("Web3Modal instance is", web3Modal);
}

/**
 * Kick in the UI action after Web3modal dialog has chosen a provider
 */
async function fetchAccountData() {
  // Disable to call this function to avoid multi signing.
  fetchLoading = true;

  // Get a Web3 instance for the wallet
  const web3 = new Web3(provider);

  console.log("Web3 instance is", web3);

  // Get connected chain id from Ethereum node
  const chainId = await web3.eth.getChainId();
  // Load chain information over an HTTP API
  console.log(chainId);
  // const chainData = evmChains.getChain(chainId);
  // console.log(chainData);
  if (chainId !== 7700) {
    console.log("Switching network to cantos...");
    await swtichNetwork(provider, 7700);
  }

  // Get list of accounts of the connected wallet
  const accounts = await web3.eth.getAccounts();

  // MetaMask does not give you all accounts, only the selected account
  console.log("Got accounts", accounts);
  if (accounts.length === 0) {
    window.localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    return;
  }
  selectedAccount = accounts[0];

  // fire-off the refresh token
  const setupRefreshTokenPromise = setupRefreshToken(selectedAccount, provider);

  // fetch canties cnft counts which is owned by user
  const tokenContract = new web3.eth.Contract(
    [
      {
        type: "function",
        stateMutability: "view",
        outputs: [{ type: "uint256[]", name: "", internalType: "uint256[]" }],
        name: "walletOfOwner",
        inputs: [{ type: "address", name: "_owner", internalType: "address" }],
      },
    ],
    "0xcd30cfe087c1847ff3aad51f1e200b3a64abd0e2"
  );

  const nftIds = await tokenContract.methods
    .walletOfOwner(selectedAccount)
    .call();
  const userData = window.localStorage.getItem("userData");
  const WEB3_CONNECT_CACHED_PROVIDER = window.localStorage.getItem(
    "WEB3_CONNECT_CACHED_PROVIDER"
  );
  if (nftIds.length >= 3) {
    if (
      WEB3_CONNECT_CACHED_PROVIDER === undefined ||
      !WEB3_CONNECT_CACHED_PROVIDER ||
      !userData ||
      userData === undefined
    ) {
      // SetUserData({
      //   ...(await authenticate(selectedAccount, provider)),
      // });
    }
  }
  SetSelectedAccount(selectedAccount);
  SetUserCounts(nftIds.length);
  SetNFTIds(nftIds);

  // drawPortfolio(selectedAccount);
  drawPages();

  const connectButton = document.querySelector("#disconnect-wallet");
  connectButton.innerHTML = reducedAddress(selectedAccount);

  // document.querySelector('#selected-account').textContent = selectedAccount

  // Get a handl
  // const template = document.querySelector("#template-balance");
  // const accountContainer = document.querySelector("#accounts");

  // // Purge UI elements any previously loaded accounts
  // accountContainer.innerHTML = "";

  // // Go through all accounts and get their ETH balance
  // const rowResolvers = accounts.map(async (address) => {
  //   const balance = await web3.eth.getBalance(address);
  //   // ethBalance is a BigNumber instance
  //   // https://github.com/indutny/bn.js/
  //   const ethBalance = web3.utils.fromWei(balance, "ether");
  //   const humanFriendlyBalance = parseFloat(ethBalance).toFixed(4);
  //   // Fill in the templated row and put in the document
  //   const clone = template.content.cloneNode(true);
  //   clone.querySelector(".address").textContent = address;
  //   clone.querySelector(".balance").textContent = humanFriendlyBalance;
  //   accountContainer.appendChild(clone);
  // });

  // // Because rendering account does its own RPC commucation
  // // with Ethereum node, we do not want to display any results
  // // until data for all accounts is loaded
  // await Promise.all(rowResolvers);

  // Display fully loaded UI for wallet data
  document.querySelector("#connect-wallet").style.display = "none";
  document.querySelector("#disconnect-wallet").style.display = "block";

  await setupRefreshTokenPromise;

  // Enable to call this function
  fetchLoading = false;
}

/**
 * Fetch account data for UI when
 * - User switches accounts in wallet
 * - User switches networks in wallet
 * - User connects wallet initially
 */
async function refreshAccountData() {
  // If any current data is displayed when
  // the user is switching acounts in the wallet
  // immediate hide this data
  document.querySelector("#disconnect-wallet").style.display = "none";
  document.querySelector("#connect-wallet").style.display = "block";

  // Disable button while UI is loading.
  // fetchAccountData() will take a while as it communicates
  // with Ethereum node via JSON-RPC and loads chain data
  // over an API call.
  document
    .querySelector("#connect-wallet")
    .setAttribute("disabled", "disabled");
  await fetchAccountData(provider);
  document.querySelector("#connect-wallet").removeAttribute("disabled");
}

/**
 * Connect wallet button pressed.
 */
async function onConnect() {
  console.log("Opening a dialog", web3Modal);
  const cachedProviderName = JSON.parse(
    localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")
  );
  if (cachedProviderName === null || !cachedProviderName) {
    try {
      provider = await web3Modal.connect();
      SetProvider(provider);
    } catch (e) {
      console.log("Could not get a wallet connection", e);
      return;
    }
  } else {
    var generalProvider = null;
    if (cachedProviderName === "injected") {
      generalProvider = window.ethereum;
    } else if (cachedProviderName === "walletconnect") {
      generalProvider = new WalletConnectProvider({
        infuraId: "8043bb2cf99347b1bfadfb233c5325c0",
        rpc: { 7700: "https://canto.gravitychain.io/" },
      });
    }
    provider = generalProvider;
    SetProvider(generalProvider);
  }

  // Subscribe to accounts change
  provider.on("accountsChanged", (accounts) => {
    if (fetchLoading === false) {
      fetchAccountData();
    }
  });

  // Subscribe to chainId change
  provider.on("chainChanged", (chainId) => {
    if (fetchLoading === false) {
      fetchAccountData();
    }
  });

  // Subscribe to networkId change
  provider.on("networkChanged", (networkId) => {
    if (fetchLoading === false) {
      fetchAccountData();
    }
  });

  await refreshAccountData();
}

/**
 * Disconnect wallet button pressed.
 */
async function onDisconnect() {
  console.log("Killing the wallet connection", provider);

  // TODO: Which providers have close method?
  if (provider.close) {
    await provider.close();

    // If the cached provider is not cleared,
    // WalletConnect will default to the existing session
    // and does not allow to re-scan the QR code with a new wallet.
    // Depending on your use case you may want or want not his behavir.
    await web3Modal.clearCachedProvider();

    window.localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    window.localStorage.removeItem("refresh-token");
    provider = null;
    SetProvider(provider);
  }

  if (web3Modal.clearCachedProvider) {
    await web3Modal.clearCachedProvider();
    window.localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    window.localStorage.removeItem("refresh-token");
    provider = null;
    SetProvider(provider);
  }

  selectedAccount = null;
  SetSelectedAccount(selectedAccount);

  ClearUserData();

  // Set the UI back to the initial state
  document.querySelector("#connect-wallet").style.display = "block";
  document.querySelector("#disconnect-wallet").style.display = "none";
}

// Switch Network if current network is wrong

async function swtichNetwork(provider, toId) {
  try {
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${toId.toString(16)}` }],
    });
  } catch (switchError) {
    // 4902 error code indicates the chain is missing on the wallet
    console.log(switchError, NETWORKDATA[toId]);
    if (switchError.code === 4902) {
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: NETWORKDATA[toId].chainId,
              rpcUrls: NETWORKDATA[toId].rpcUrls,
              chainName: NETWORKDATA[toId].chainName,
              nativeCurrency: NETWORKDATA[toId].nativeCurrency,
              blockExplorerUrls: NETWORKDATA[toId].blockExplorerUrls,
            },
          ],
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
}

/**
 * Main entry point.
 */
window.addEventListener("load", async () => {
  init();
  document
    .querySelector("#connect-wallet")
    .addEventListener("click", onConnect);
  document
    .querySelector("#disconnect-wallet")
    .addEventListener("click", onDisconnect);

  const cachedProviderName = JSON.parse(
    localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")
  );
  if (cachedProviderName === null || !cachedProviderName) {
  } else {
    onConnect();
  }
});

/**
 * Updated Date: 2023/02/27
 * Author: Luckypanda
 * Functionality: Add charts in portfolio page
 */

async function analysisChart() {
  const ava = checkAvailability();
  if (ava === false) return;
  const address = GetSelectedAccount();
  const chartSdk = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-project-0-bgadz",
    filter: { walletAddress: address.toLowerCase() },
    showAttribution: false,
    autoRefresh: true,
    maxDataAge: 60,
    widthMode: "scale",
    getUserToken: GetUserData,
  });
  const chart = chartSdk.createChart({ chartId: portfolioAnalysis });

  await chart.render(document.getElementById("portfolio-collection-analysis"));

  $("#refresh").on("click", () => {
    chart.refresh();
  });
}

async function circleChart() {
  const ava = checkAvailability();
  if (ava === false) return;
  const address = GetSelectedAccount();
  const chartSdk = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-project-0-bgadz",
    filter: { walletAddress: address.toLowerCase() },
    showAttribution: false,
    autoRefresh: true,
    maxDataAge: 60,
    widthMode: "scale",
    getUserToken: GetUserData,
  });
  const chart = chartSdk.createChart({ chartId: portfolioCircle });

  await chart.render(document.getElementById("portfolio-collection-circle"));

  $("#refresh").on("click", () => {
    chart.refresh();
  });
}

async function collectionCountsChart() {
  const ava = checkAvailability();
  if (ava === false) return;
  const address = GetSelectedAccount();
  const chartSdk = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-project-0-bgadz",
    filter: { walletAddress: address.toLowerCase() },
    showAttribution: false,
    autoRefresh: true,
    maxDataAge: 60,
    widthMode: "scale",
    // getUserToken: GetUserData,
  });
  const chart1 = chartSdk.createChart({ chartId: portfolioCount1 });
  const chart2 = chartSdk.createChart({ chartId: portfolioCount2 });

  chart1.render(document.getElementById("portfolio-collection-counts-1"));
  chart2.render(document.getElementById("portfolio-collection-counts-2"));

  $("#refresh").on("click", () => {
    chart1.refresh();
    chart2.refresh();
  });
}

async function drawPortfolioTop4Data() {
  const ava = checkAvailability();
  if (ava === false) return;
  const address = GetSelectedAccount();
  const chartSdk = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-project-0-bgadz",
    filter: { walletAddress: address.toLowerCase() },
    showAttribution: false,
    autoRefresh: true,
    maxDataAge: 60,
    widthMode: "scale",
    getUserToken: GetUserData,
  });
  const cantiCountChart = chartSdk.createChart({ chartId: cantiCount });
  cantiCountChart.render(document.getElementById("canti-counts"));
  const portValueChart = chartSdk.createChart({ chartId: portfolioValue });
  portValueChart.render(document.getElementById("total-value"));
  const numberCollectionChart = chartSdk.createChart({
    chartId: numberCollection,
  });
  numberCollectionChart.render(document.getElementById("collection-counts"));
  const totalNftsOwnChart = chartSdk.createChart({ chartId: totalNftsOwn });
  totalNftsOwnChart.render(document.getElementById("total-nfts"));
}
